module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ISP Disconnect","short_name":"ApexEdge","description":"Simplify your ISP conversion! | ISP Disconnect","lang":"en","start_url":"/","background_color":"#20232a","theme_color":"#20232a","display":"minimal-ui","icon":"/codebuild/output/src880188832/src/src/images/icons/favicon-32x32.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"d7a31947dbed80c10a9efd665f4980de"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
